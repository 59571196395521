/*
* ===================================================
*     Avatar
* ===================================================
*/

/*
    Avatars
   ------------------------
*/

.avatar {
    display: inline-block;
    position: relative;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border: $border-color;
    border-radius: 50%;
    background: $white;
    box-shadow: $box-shadow;
    line-height: 3rem;

    &.avatar-xxs {
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
    }

    &.avatar-xs {
        width: 1.8rem;
        height: 1.8rem;
        line-height: 1.8rem;
    }

    &.avatar-sm {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
    }

    &.avatar-lg {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
    }

    &.avatar-xl {
        width: 7rem;
        height: 7rem;
        line-height: 7rem;
    }

    &.avatar-xxl {
        width: 10rem;
        min-width: 10rem;
        height: 10rem;
        line-height: 10rem;
    }

    &.avatar-border-white {
        border: solid 2px $white;
    }

    &.avatar-flag {
        width: auto !important;
    }
}

.avatar-stacked {
    margin-left: -0.5rem;
}

.avatar-text {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 2.6rem;
}

@each $color, $value in $theme-colors {
    .avatar-#{$color}-light {
        color: $value;
        background-color: tint-color($value, 80%);
    }
}
