/* ==========================================
    CHOICES.JS
   ========================================== */
.choices {
    min-width: 10rem !important;
}

.choices__list--dropdown .choices__item--selectable {
    padding-right: 3rem !important;
}

.choices__inner {
    min-height: unset !important;
    border-radius: 0 !important;

    &.rounded {
        border-radius: $border-radius !important;
    }

    &.form-control-sm {
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
    }
    &.form-control-lg {
        padding: $input-btn-padding-y-lg $input-btn-padding-x-lg !important;
    }
    &.form-control {
        padding: $input-btn-padding-y $input-btn-padding-x !important;
    }

    &.form-select-sm {
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
    }
    &.form-select-lg {
        padding: ($input-btn-padding-y-lg - 0.1055) $input-btn-padding-x-lg !important;
    }
    &.form-select {
        background-image: none !important;
        &:not(.form-select-sm):not(.form-select-lg) {
            padding: ($input-btn-padding-y - 0.125) $input-btn-padding-x !important;
        }
    }
}

.choices__list--single {
    padding: 2px !important;
}

.choices__placeholder {
    opacity: 1 !important;
}

.choices__list--dropdown {
    border-color: $input-border-color !important;
}

.os-theme-dark > .os-scrollbar-vertical,
.os-theme-light > .os-scrollbar-vertical {
    width: 0.25rem;
    background: $dark;
    padding: 0;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: darken($primary, 10%);
    &:hover,
    &:focus {
        background: darken($primary, 10%);
    }
}
