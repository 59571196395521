/*
* ==========================================================
*     FORMS ELEMENTS
* ==========================================================
*/
// General form control placeholder
.form-control {
    &::placeholder {
        font-size: 0.85rem;
    }
}

// Material Inputs
.input-material-group {
    position: relative;
    padding-top: 0.85rem;

    .input-material {
        border: none;
        background: none;
        padding: $input-btn-padding-y 0;
        border-bottom: 1px solid $gray-300;
        display: block;
        width: 100%;
        &:focus-visible {
            outline: none;
        }
        &.js-validate-error-field {
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
        }

        &.is-invalid {
            position: relative;
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
        &.is-valid {
            position: relative;
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
            border-color: $success;
            & + label {
                color: $success !important;
            }
        }
    }

    .label-material {
        position: absolute;
        top: 0.85rem + $input-btn-padding-y;
        left: 0;
        font-size: 0.9rem;
        color: lighten($form-label-color, 20%);
        cursor: text;
        transition: all 0.3s;
        transform-origin: left center;
        transform: none;

        &.active {
            transform: scale(0.85);
            top: 0;
            color: $primary;
        }
    }
}

.js-validate-error-label {
    font-size: $form-text-font-size !important;
    margin-top: $form-text-margin-top !important;
    color: $danger !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: $danger !important;
}
