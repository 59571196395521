/*
* ==========================================================
*     LOGIN PAGE
* ==========================================================
*/
.login-page {
    min-height: 100vh;
    position: relative;

    &::before {
        content: "";
        display: block;
        z-index: -1;
        background: url(../img/bg.jpg);
        background-size: cover;
        filter: blur(10px);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .container {
        // min-height: 100vh;
        z-index: 999;
    }
}
