/*
* ==========================================================
*     CHARTS - PREMIUM
* ==========================================================
*/

.gauge-container {
    position: relative;
}

.gauge-canvas {
    display: block;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: auto;
}

.gauge-label {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 1.2rem;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.sparkline {
    fill: none;
}

@each $color, $value in $theme-colors {
    .sparkline--#{$color} {
        stroke: $value;
    }
    .sparkline--#{$color}.sparkline--filled {
        fill: rgba($value, 0.3);
    }
}

.sparkline--cursor {
    stroke: orange;
}

.sparkline--spot {
    fill: red;
    stroke: red;
}

*[hidden] {
    display: none;
}

.sparkline-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 9999;
}
