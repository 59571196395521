/*
* ==========================================================
*     SPINKIT PRELOADERS
* ==========================================================
*/

$spinkit-spinner-margin: 0 !default;
$spinkit-size: 40px !default;
$spinkit-spinner-color: $primary !default;


.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 999999;
    transition: all 0.4s;

    &.opacity-0 {
        visibility: hidden;
    }
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px !important;
    margin-top: -20px !important;
}

@import "spinners";