/*
* ==========================================================
*     GENERAL
* ==========================================================
*/
.card {
    margin-bottom: 2rem;
    transition: all 0.3s;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.card-header {
    border-bottom: 1px solid $gray-300;
}

.card-footer {
    border-top: 1px solid $gray-200;
    background: rgba($black, 0.03);

    &:last-child {
        @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
    }
}

.card-cap-transparent {
    border-bottom: none;
    background: rgba($black, 0.03);
}

.btn-primary {
    color: #fff !important;

    &:hover,
    &:focus {
        color: #fff !important;
    }
}

.btn-outline-primary {
    &:hover {
        color: #fff;
    }
}

.card-collapse-link {
    position: relative;
    padding-right: 20px;

    &[data-bs-toggle="collapse"] {
        &::before {
            display: block;
            width: 20px;
            height: 20px;
            content: "";
            transition: all 0.3s;
            transform: translateY(-50%) rotate(90deg);
            transform-origin: center center;
            background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Left%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='%23aaa' stroke-miterlimit='10' stroke-width='5' d='M39 20.006L25 32l14 12.006' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
            background-position: right center;
            @include position(absolute, $top: 50%, $right: 0);
        }

        &[aria-expanded="true"] {
            &::before {
                transform: translateY(-50%) rotate(-90deg);
            }
        }
    }
}

.dropdown-toggle:not(.btn) {
    &::after {
        display: inline-block;
        width: 20px;
        height: 14px;
        margin: 0;
        content: "";
        transition: all 0.3s;
        transform: rotate(-90deg);
        transform-origin: center center;
        vertical-align: middle;
        border: none;
        background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Left%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='white' stroke-miterlimit='10' stroke-width='5' d='M39 20.006L25 32l14 12.006' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
        background-position: right center;
        background-size: 100%;
    }
}

p {
    font-size: $font-size-base * 0.9;
}

a {
    display: inline-block;
    transition: all 0.3s;
    text-decoration: none;
}

body {
    overflow-x: hidden;
}

i {
    display: inline-block;
}

span {
    display: inline-block;
}

.btn-xs {
    padding: 2px 5px !important;
    border-radius: 2px !important;
    font-size: 0.7em !important;
}

.page {
    @include position(absolute, 0, 0);
    width: calc(100% - 200px);
    min-height: 100vh;
    padding-bottom: 3rem;
    transition: width 0.3s linear;
    background-color: #f4f7fa;
    transition: all 0.3s;

    &.active {
        width: calc(100% - 70px);
    }

    @include media-breakpoint-down(xl) {
        width: 100%;

        &.active {
            width: 100%;
        }

        &.active-sm {
            width: 100%;
            // margin-right: -200px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .container-fluid {
        padding: 0 3rem;
    }
}

/*
* ==========================================================
*     Main NAVBAR
* ==========================================================
*/

nav.navbar {
    padding: 0.8rem 0;
    background: #393836;
    width: calc(100% - 200px);
    transition: all 0.3s;
    margin-left: 200px;

    &.active {
        width: calc(100% - 70px) !important;
        margin-left: 70px !important;
    }

    &.active-sm {
        width: calc(100% - 200px) !important;
        margin-left: 200px !important;
    }

    @include media-breakpoint-down(xl) {
        width: 100%;
        margin-left: 0;
    }

    .badge {
        @include position(absolute, 0, 0);
    }

    .dropdown-menu {
        display: block;
        visibility: hidden;
        transform: translateY(0.5rem);
        opacity: 0;
    }

    .dropdown-menu.show {
        display: block;
        visibility: visible;
        transition: 0.2s ease all;
        transform: translateY(0px);
        opacity: 1;
    }
}



@include media-breakpoint-down(sm) {
    nav.navbar {
        .dropdown {
            position: static;
        }

        .dropdown-menu {
            right: 2.5vw;
            left: 2.5vw;
            width: 90vw;
            margin: 0 auto;
        }
    }
}

/*
* ==========================================================
*     SIDE NAVBAR
* ==========================================================
*/

.side-navbar {
    @include position(fixed, 0, null, null, 0);
    @include size(200px, 100%);
    overflow-x: hidden;
    overflow-y: auto;
    width: 200px;
    transition: all 0.3s;
    text-align: left;
    color: #fff;
    background: #393836;
    z-index: 999;
    transition: all 0.3s;
    overflow: hidden;

    &-inner {
        background: #393836;
    }

    .scrolled-wrapper {
        @include size(100%, 100%);
    }

    .sidebar-header {
        background: #292929;
    }

    &.hide {
        left: -70px;
    }

    @include media-breakpoint-down(xl) {
        left: -200px;
        width: 200px;
        text-align: center;

        &.show-sm {
            left: 0 !important;
            &::after {
                content: '';
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #000;
                opacity: 0.4;
                transition: all 0.3s;
                z-index: -1;
            }
        }

        .brand-small {
            display: none;
        }

        .sidenav-header-inner {
            display: block;
        }
    }

    .brand-small {
        display: none;
    }


    /*  Side navbar small ------------------------ */
    &.shrink {
        width: 70px;
        text-align: center;
        @include media-breakpoint-down(xl) {
            width: 200px;
        }

        .brand-small {
            display: block;
        }

        .sidenav-header-inner {
            display: none;
            
        }
    }
}

.sidebar-item {
    li a {
        padding-left: 2.5rem;
        background: #292929;
    }

    &.active {
        >.sidebar-link {
            color: #fff;
            background: $primary;

            &:hover {
                background: $primary;
            }
        }
    }

    /* submenu item active */
    li.active>a.sidebar-link {
        color: #fff;
        background: lighten($primary, 10%);
    }

    .collapse,
    .collapsing {
        border-left: 2px solid $primary;
        background: #292929;

        li:not(.active) .sidebar-link {

            &:hover,
            &:focus {
                background: #121212;
            }
        }
    }
}

.sidebar-link {
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 10px 15px;
    text-decoration: none;
    text-overflow: ellipsis;
    color: inherit;
    font-size: 0.9rem;
    font-weight: 300;
    align-items: center;

    &:hover {
        color: #fff;
        background: $primary;
    }

    &[data-bs-toggle="collapse"] {
        &::before {
            display: block;
            width: 20px;
            height: 20px;
            content: "";
            transition: all 0.3s;
            transform: translateY(-50%) rotate(-90deg);
            transform-origin: center center;
            background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title' aria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAngle Left%3C/title%3E%3Cdesc%3EA line styled icon from Orion Icon Library.%3C/desc%3E%3Cpath data-name='layer1' fill='none' stroke='white' stroke-miterlimit='10' stroke-width='5' d='M39 20.006L25 32l14 12.006' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
            background-position: right center;
            @include position(absolute, $top: 50%, $right: 10px);
        }
    }

    &[aria-expanded="true"],
    &[aria-expanded="aria-expanded"] {
        background: $primary;

        &::before {
            transform: translateY(-50%) rotate(90deg);
        }

        &:hover {
            color: $white;
            background: $primary;
        }
    }

    .badge {
        margin-left: 0.25rem;
        vertical-align: middle;
    }
}

.side-navbar.shrink {
    .sidebar-item {
        a.sidebar-link {
            flex-direction: column;
            padding: 15px 2px;
            font-size: 0.7rem;

            &[data-bs-toggle="collapse"] {
                &::before {
                    transform: translateX(50%) rotate(-90deg);
                    @include position(absolute, auto, 50%, 0, auto);
                }

                &[aria-expanded="true"],
                &[aria-expanded="aria-expanded"] {
                    &::before {
                        transform: translateX(50%) rotate(90deg);
                    }
                }
            }

            svg {
                margin-right: 0 !important;
                margin-bottom: 4px;
            }
        }
    }

    .sidebar-link {
        .badge {
            margin-left: 0;
        }
    }
}