/*
* ==========================================================
*     Profile
* ==========================================================
*/

.avatar {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    display: inline-block;
    background: #ced4da no-repeat center/cover;
    position: relative;
    text-align: center;
    color: #868e96;
    font-weight: 600;
    vertical-align: bottom;
    &.avatar-md {
        width: 3rem;
        height: 3rem;
    }
    &.avatar-lg {
        width: 4rem;
        height: 4rem;
    }
    &.avatar-xl {
        width: 5rem;
        height: 5rem;
    }

    &.avatar-xxl {
        width: 7rem;
        height: 7rem;
        min-width: 7rem;
    }
}

.card-profile .card-header {
    height: 9rem;
    background-size: cover;
    background-position: center center;
}

.card-profile-img {
    max-width: 8rem;
    margin-top: -6rem;
    margin-bottom: 1rem;
    border: 3px solid #fff;
    border-radius: 100%;
}

.card-status {
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    height: 3px;
    background: rgba(0, 40, 100, 0.12);
}

.card-category {
    text-transform: uppercase;
    font-weight: bold;
    color: $gray-600;
    font-size: 0.9rem;
}
